<template>
  <div class="flex items-center justify-between">
    <div class="flex items-center gap-2">
      <UButton
        v-if="allowGoBack"
        icon="i-heroicons-arrow-left"
        color="gray"
        size="xs"
        variant="ghost"
        data-test="back-button"
        @click="onNavigate(lastPage)"
      />
      <Tooltip v-if="!canEdit" :text="title">
        <template #default="{ getTextRef }">
          <h3 :id="hash" :ref="getTextRef" class="text-xl font-semibold line-clamp-1 break-all">
            {{ title }}
          </h3>
        </template>
      </Tooltip>
      <UInput
        v-else
        v-model="inputValue" :ui="{
          base: 'line-clamp-1 break-all truncate',
          size: {
            xl: 'text-xl font-semibold',
          }
        }" :padded="false" size="xl"
        variant="none"
        class="text-xl"
        :placeholder="placeholder"
        data-test="input-board-setting-title"
        @change="emits('change', inputValue)"
      />
      <slot />
    </div>
    <UButton
      color="gray"
      variant="ghost"
      icon="i-heroicons-x-mark-20-solid"
      size="xs"
      data-test="close-setting-btn"
      @click="closeSidebarSetting"
    />
  </div>
</template>

<script lang="ts" setup>
const props = defineProps({
  hash: {
    type: String,
    required: true,
  },
  title: {
    type: String,
    required: true,
  },
  allowGoBack: {
    type: Boolean,
    default: true,
  },
  lastPage: {
    type: String,
    default: 'index',
  },
  canEdit: {
    type: Boolean,
    default: false,
  },
  placeholder: {
    type: String,
    default: 'Enter title',
  },
})

const emits = defineEmits(['change'])

const route = useRoute()
const router = useRouter()
const { setSettingTab, closeSettings } = useBoardSettingsNavigator()
const { isPreventOpenTaskDetail } = useModalManager()
const closeSidebarSetting = () => {
  isPreventOpenTaskDetail.value = false
  nextTick(() => {
    closeSettings()
  })
}

const inputValue = ref(props.title)

const onNavigate = async (page: string) => {
  const { id, ...cleanQuery } = route.query
  if (page === 'index' && id) {
    await router.push({ query: cleanQuery })
  }

  setSettingTab(page)
}

watch(
  () => props.title,
  (value) => {
    inputValue.value = value
  })
</script>
